import React from "react"
import { Image } from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./workItem.css"

const WorkPage = ({ pageContext: { work } }) => (
  <Layout headerImage={(
    <Image 
      src={require(`../images/${work.bannerImage}`)}
      className='d-block mx-auto'
    />
  )}>
    <SEO title={`Work - ${work.title}`} />
    <div class="d-flex mb-4 flex-wrap">
      <div class="d-flex align-items-center mb-0 flex-grow-1">
        <h1 class="mb-0">{work.title}</h1>
        {work.outsideLink && (
          <a 
            href={work.outsideLink} 
            target="_blank" 
            rel="noreferrer"
            class="border-left pl-3 ml-3 mt-1"
          >
            View Site
          </a>
        )}
      </div>
      <div class="ml-auto work-details">
        <div class="d-flex align-items-center">
          <h4 class="mb-0 mr-2">Company:</h4>
          <p class="mb-0">{work.company}</p>
        </div>
        <div class="d-flex align-items-center">
          <h4 class="mb-0 mr-2">Release Date:</h4>
          <p class="mb-0">{work.date}</p>
        </div>
      </div>
    </div>
    <p style={{ whiteSpace: "pre-wrap" }}>
      {`${work.shortDescription}\n\n${work.description || ''}`}
    </p>
  </Layout>
)

export default WorkPage
